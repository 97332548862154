const localStorageMiddleware = (store) => (next) => (action) => {
    const result = next(action);

    if (action.type.startsWith('CART_')) {
        const state = store.getState();
        localStorage.setItem('cart', JSON.stringify(state.cart.cartItems));
    }


    return result;
};

export default localStorageMiddleware;