import React, {Fragment} from "react";

import styled from "styled-components";
import NavItem from "../navigator/nav-item";
import {setIsMenuModalExpanded} from "../../actions/common.actions";
import {ModalMenu} from "../menu-modal/menu-modal";
import {useDispatch, useSelector} from "react-redux";
import {LoginPopup} from "../login-modal/login-popup";

const StyledHeader = styled.div`
  height: auto;
  display: block;
  margin-top: 20px;
  position: relative;
  z-index: 700;
  line-height: 0.5;

  img {
    //position: relative;
    z-index: 2;
    border: none;
    vertical-align: middle;
    height: 60px;
    //background: transparent;
  }

  .menu-btn {
    width: 40px;
    height: 40px;
    background: url(/static/svg/menu.svg) no-repeat;
    background-size: 40px 40px;
    display: inline-block;
  }

  .head-phone {
    text-align: center;
  }
`

export const HeaderMobile = () => {

    const dispatch = useDispatch();
    const isMenuModalExpanded = useSelector((state) => state.common.isMenuModalExpanded);
    const isLoginModalExpanded = useSelector((state) => state.common.isLoginModalExpanded);

    return (
        <StyledHeader>
            <div className="row h-100 mobile-row">
                <div className="col-5 my-auto head-wrapper">
                    <div className="head-logo">
                        <NavItem
                            to=""
                            title="basename"
                        >
                            <img src="/static/png/LOGO.png" alt='logo'/>
                        </NavItem>
                    </div>
                    <div className="header-text">
                        Alex Parts
                    </div>
                </div>
                <div className="col-5 my-auto">
                </div>
                <div className="col-1 my-auto text-right">
                    <span role="button" className="menu-btn" onClick={() => {
                        dispatch(setIsMenuModalExpanded(true));
                    }}/>
                    {
                        isMenuModalExpanded ? (<ModalMenu/>) : (
                            <Fragment/>
                        )
                    }
                    {isLoginModalExpanded && (<LoginPopup/>)}
                </div>
            </div>
            <div className="row h-100 mobile-row">
                <div className="head-phone" data-original-title="" title="">
                    <a href="tel:+7 910 476 51 20">
                        +7 910 476 51 20
                    </a>
                </div>
            </div>
        </StyledHeader>
    )
}