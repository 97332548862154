import React, {Fragment, useEffect, useState} from "react";

import styled from "styled-components";
import {CommonTemplate} from "../components/common-template";
import {useDispatch, useSelector} from "react-redux";
import {addToCart, removeAllFromCart, subtractItemFromCart} from "../actions/cart.actions";
import api from "../utils/api";

const StyledCartPage = styled.div`
  .cart-container {
    position: relative;
    background: transparent;
    padding: 60px;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }

  .col {
    font-weight: 500;
  }

  .center {
    text-align: center;
    font-weight: 600;
  }

  .control {
    cursor: pointer;
    font-weight: 500;

    &:hover {
      font-size: 22px;
    }
  }

  .table-wrapper {
    overflow-x: scroll;
  }

  .info-container {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #dee2e6;
    width: 100%;
    height: auto;
  }

  .info-price {
    width: 100%;
    display: flex;
    justify-content: space-between;

  }

  .cart-buttons {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 400px;
    padding: 20px;
  }

  .button-submit {
    background-image: linear-gradient(180deg, ${props => props.theme.colors.primaryOrange}, ${props => props.theme.colors.lightOrange});
    width: 150px;
    height: 50px;
    border: 1px solid ${props => props.theme.colors.primaryOrange};
    border-radius: 15px;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-image: linear-gradient(153deg, #f67767, #ef4c40);
    }

    &:active {
      background-image: linear-gradient(153deg, #d04e3f, #b72f25);
    }
  }

  .button-reject {
    width: 150px;
    height: 50px;
    border: 1px solid ${props => props.theme.colors.primaryOrange};
    border-radius: 15px;
    color: ${props => props.theme.colors.primaryOrange};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
      //color: white;
    }

    &:active {
      background-color: #eeeeee;
    }
  }

  .submit-loader-wrapper {
    width: 100%;
    padding: 20px;
    height: 50px;
    display: flex;
    justify-content: end;
  }

  .submit-loader {
    display: inline-block;
    height: 50px;
    width: 50px;
    background: url(/static/svg/loader.svg);
    background-size: 50px 50px;
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const CartPage = () => {
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.cartItems);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isSubmitRequestFetching, setIsSubmitRequestFetching] = useState(false);

    useEffect(() => handleTotalPrice(),
        // eslint-disable-next-line
        [cartItems]);
    const handleTotalPrice = () => {
        let tempPrice = 0
        // eslint-disable-next-line array-callback-return
        cartItems.map(item => {
            tempPrice += Math.round(Number(item.total_price) * Number(item.quantity));
        })
        setTotalPrice(tempPrice);
    }

    const emptyCart = () => {
        dispatch(removeAllFromCart());
    }
    const cartAdd = (part) => {
        console.log("part is: ", part)
        let itemCopy = JSON.parse(JSON.stringify(part));
        console.log("ITEM_COPY: ", itemCopy);
        itemCopy['quantity'] = 1;
        dispatch(addToCart(itemCopy));
        handleTotalPrice();
    }
    const cartSubtract = (id) => {
        dispatch(subtractItemFromCart(id));
        handleTotalPrice();
    }
    const handleSubmitButton = () => {
        setIsSubmitRequestFetching(true);
        const timer = setTimeout(async () => {
            const {data: res} = await api.post(
                'order/create-order/',
                {
                    order: cartItems
                }
            );
            if (res.redirect_url) {
                window.open(res.redirect_url);
                emptyCart();
            }
            if (res.status === 201 || res.status === 200) {
                emptyCart();
            }
            setIsSubmitRequestFetching(false);
        }, 1000);
        return () => clearTimeout(timer);
    }
    return (
        <StyledCartPage>
            <CommonTemplate>
                <div className="cart-container">
                    <h1>Корзина заказов</h1>
                    {
                        Boolean(cartItems.length) && (
                            <Fragment>
                                <div className="table-wrapper">
                                    <table className="table ">
                                        <tbody>
                                        <tr>
                                            <th>Артикул</th>
                                            <th>Производитель</th>
                                            <th>Марка</th>
                                            <th>Наименование</th>
                                            <th className="price">Цена</th>
                                            <th className="center">Количество</th>
                                            <th className="center">Убрать</th>
                                            <th className="center">Добавить</th>
                                        </tr>
                                        {
                                            cartItems.map(item => {
                                                return (
                                                    <tr key={item.id}>
                                                        <th key={item.article} className="col">{item.article}</th>
                                                        <th key={item.manufacturer} className="col">{item.manufacturer}</th>
                                                        <th key={item.brand_auto} className="col">{item.brand_auto}</th>
                                                        <th key={item.name} className="col">{item.name}</th>
                                                        <th key={item.total_price}
                                                            className="col price">{Math.round(item.total_price)}</th>
                                                        <th key={item.quantity}
                                                            className="col center">{Math.round(item.quantity)}</th>
                                                        <th key={item.article + item.quantity}
                                                            className="col center control"
                                                            onClick={() => cartSubtract(item.id)}>--
                                                        </th>
                                                        <th key={item.article + item.manufacturer}
                                                            className="col center control" onClick={() => cartAdd(item)}>+
                                                        </th>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="info-container">
                                    <div className="info-price">
                                        <h4>
                                            Общая цена
                                        </h4>
                                        <h4>
                                            {totalPrice} рублей.
                                        </h4>
                                    </div>
                                </div>
                                {
                                    isSubmitRequestFetching ? (
                                        <div className="submit-loader-wrapper">
                                            <span className="submit-loader"/>
                                        </div>
                                    ) : (
                                        <div className="cart-buttons">
                                            <div className="button-container">
                                                <div className="button-reject" onClick={emptyCart}>
                                                    Очистить корзину
                                                </div>
                                                <div className="button-submit" onClick={handleSubmitButton}>
                                                    Заказать
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </Fragment>
                        )
                    }
                    {
                        !cartItems.length && (
                            <div>
                                Ваша корзина пустая!
                            </div>
                        )
                    }
                </div>
            </CommonTemplate>
        </StyledCartPage>
    )
}