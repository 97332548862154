import React from "react";

import styled from "styled-components";
import {CommonTemplate} from "../components/common-template";

const StyledDeliveryPage = styled.div`
    .delivery-container {
      //height: 400px;
      display: flex;
      justify-content: center;
    }
  .delivery-wrapper {
    position: relative;
    padding: 80px;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    h1 {
      margin-bottom: 30px;
    }
  }
`

export const DeliveryPage = () => {
    return (
        <StyledDeliveryPage>
            <CommonTemplate>

                <div className="delivery-container">
                    <div className="delivery-wrapper">
                        <div className="delivery-text"><h1>Доставка</h1><p>Уважаемые клиенты!</p>

                            <p>Наша компания предлагает следующие варианты доставки запчастей:</p>

                            <p>-Самовывоз по адресу:<br/>
                                <b>г.Москва, ул. Ермакова Роща, д. 7б, стр.8</b><br/>
                                -По городу Москва:<br/>
                                доставка корпоративным транспортом либо службой Я.Доставка<br/>
                                -За пределы города Москва:<br/>
                                +отправка с помощью компании Деловые линии (за счет получателя)<br/>
                                +отправка с помощью компании СДЭК (за счет получателя)<br/>
                                <br/>
                            </p>
                            <p> По всем интересующим вас вопросам можно:<br/>
                                -связаться по телефонам:<br/>
                                <b>+79300369476</b><br/>
                                <b>+79104765120</b><br/>
                                -написать на e-mail:<br/>
                                <a href="mailto:Info@alexparts.ru"
                                   target="_blank"><b>info@alexparts.ru</b></a><br/>
                                </p>
                        </div>
                    </div>
                </div>
            </CommonTemplate>
        </StyledDeliveryPage>
    )
}