import React, {useEffect} from "react";

import styled from "styled-components";
import {CommonTemplate} from "../components/common-template";
import {Outlet, useLocation} from "react-router-dom";
import NavItem from "../components/navigator/nav-item";
import {useDispatch, useSelector} from "react-redux";
import {setAccountSection} from "../actions/common.actions";

const StyledAccountPage = styled.div`
  div {
    background: transparent;
  }

  .account-wrapper {
    border-radius: 10px;
    position: relative;
    min-height: 200px;
    background: white;
    display: flex;
    flex-direction: row;
  }

  .choice-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    height: 200px;
    width: 250px;
  }

  .account-content {
    width: 950px;
    padding: 50px;

    //&:before {
    //  content: "";
    //  display: flex;
    //  position: absolute;
    //  //height: 100px;
    //  //margin-top: 50px;
    //  border-left: 2px solid #efefef;
    //}
  }

  .list-group-item {
    display: flex;
    border: none;

    span {
      background: transparent;
    }

    cursor: pointer;
    //color: black;
  }

  .active {
    color: white;
    border-color: transparent;
    background-image: linear-gradient(153deg, ${props => props.theme.colors.lightOrange}, ${props => props.theme.colors.primaryOrange});

    .gear {
      filter: brightness(0) invert(1);
    }

    .user {
      filter: brightness(0) invert(1);
    }
  }

  .gear {
    display: flex;
    height: 25px;
    width: 25px;
    background: url(/static/svg/gear.svg) no-repeat !important;
  }

  .user {
    display: flex;
    height: 25px;
    width: 25px;
    background: url(/static/svg/user.svg) no-repeat !important;
  }

  .account-divider {
    //padding: 10px;
    border-right: 1px solid ${props => props.theme.colors.primaryGray};
  }

  @media (max-width: 1000px) {
    .account-wrapper {
      flex-direction: column;
    }

    .choice-wrapper {
      width: 100%;
      height: 150px;
    }

    .account-divider {
      width: 100%;
      border-bottom: 1px solid ${props => props.theme.colors.primaryGray};
    }

    .list-group {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .account-content {
      width: 700px;
    }
  }

  @media (max-width: 767px) {
    .account-wrapper {
      flex-direction: column;
    }

    .choice-wrapper {
      width: 100%;
      height: 150px;
    }

    .account-divider {
      width: 100%;
      border-bottom: 1px solid ${props => props.theme.colors.primaryGray};
    }

    .list-group {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    .account-content {
      width: 400px;
      padding: 0;
    }
  }
`

export const AccountPage = () => {
    const dispatch = useDispatch();
    const currentLocation = useLocation();
    const accountSection = useSelector((state) => state.common.accountSection);
    useEffect(() => {
        dispatch(setAccountSection(currentLocation.pathname.split('/').pop() === 'orders' ? 'orders' : 'personal'));
    }, [currentLocation, dispatch]);
    return (
        <StyledAccountPage>
            <CommonTemplate>
                <div className="account-wrapper">
                    <div className="choice-wrapper">
                        <nav>
                            <div className="position-sticky">
                                <div className="list-group list-group-flush mx-3 mt-4">
                                    <NavItem to="orders">
                                        <div
                                            className={`list-group-item list-group-item-action py-2 ripple ${accountSection === "orders" ? "active" : ""}`}>
                                            <span className="gear me-3"/><span>Заказы</span>
                                        </div>
                                    </NavItem>
                                    <NavItem to="personal">
                                        <div
                                            className={`list-group-item list-group-item-action py-2 ripple ${accountSection === "personal" ? "active" : ""}`}>
                                            <span className="user me-3"/><span>О себе</span>
                                        </div>
                                    </NavItem>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="account-divider"/>
                    <div className="account-content">
                        <Outlet/>
                    </div>
                </div>

            </CommonTemplate>
        </StyledAccountPage>
    )
}