import React, {useState} from "react";

import styled from "styled-components";

import api from "../../utils/api";
import {useDispatch} from "react-redux";
import {addToCart} from "../../actions/cart.actions";


const StyledSearchField = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 111;
  background-color: transparent !important;
  overflow: visible;

  .search-form {
    width: 400px;
    margin: 50px auto 0;
    position: relative;
    //background-color: white;

    &:focus {
      outline: 0;
    }

    input {
      font-size: 14px;
      height: 56px;
      border-radius: 28px;
      padding: 0.375rem 1.5rem;
      border: none;
      opacity: 0.4;
      //width: 100%;

      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
        opacity: 1 !important;
        z-index: 1;
      }
    }

    .input-group {
      border-radius: 28px;
      border: 1px solid #D6D6D6;
      padding: 1px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      //background-color: white;
      z-index: 999;
      width: 100%;

      &-append {
        background: transparent;
        //background-color: white;
        display: flex;
        margin-left: -1px;
      }

      &:hover {
        border: 1px solid #a4b5c1;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, .1);
      }

      .form-control {
        display: block;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        //background-color: #fff;
        background-clip: padding-box;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      }

      & > .form-control {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
      }

      &-append {
        margin-left: -1px;
        display: flex;

        button {
          background-image: linear-gradient(153deg, ${props => props.theme.colors.lightOrange}, ${props => props.theme.colors.primaryOrange});
          border-radius: 28px;
          color: white;
          padding: 18px 43px;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          border: none;
        }
      }
    }
  }

  .search-results {
    display: block;
    overflow-x: scroll;
    max-width: 1280px;
    top: 20px;
    padding: 60px 40px;
    font-size: 12px;
    line-height: 22px;
    width: 95% !important;
    font-weight: 600;
    z-index: 9999;
    background: white;
    box-shadow: 4px 4px 10px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    border-radius: 10px;
    margin: 10px auto 0;

    table {
      border-collapse: collapse;
      font-size: 12px;
      width: 100%;
    }

    &:after {
      bottom: 100%;
      left: 50%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      background: transparent;
      border: 20px solid rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      margin-left: -20px;
    }

    .search-close {
      color: #fff;
      position: absolute;
      right: 0;
      top: 20px;
      font-size: 30px;
      cursor: pointer;
      width: 19px;
      height: 18px;
      display: inline-block;
      background: url(static/svg/close-menu.svg) no-repeat;
    }

    .table-wrapper {
      display: inline-block;
      width: 100%;
      overflow: auto;
      margin-bottom: 0;
    }

    .table {
      overflow: visible;
      width: 100%;

      .tr {
        background: ${props => props.theme.colors.primaryGray};

        &-odd {
          //background-color: transparent;
        }

        &-even {
          background: ${props => props.theme.colors.primaryGray};
        }
      }

      tbody {
        tr {
          th {
            color: ${props => props.theme.colors.darkGray};
            text-align: center;
            text-transform: uppercase;
            white-space: nowrap;
            border: none;
            padding-bottom: 7px;
          }
        }
      }

      .center-field {
        margin-left: auto;
        margin-right: auto;
      }

      .yes-field {
        color: #73bf3e;
      }

      .no-field {
        color: #c0c020;
      }

      .positive {
        display: block;
        content: " ";
        height: 20px;
        width: 20px;
        background: transparent url("/static/svg/yes.svg");
        background-size: 20px 20px;
      }

      .negative {
        display: block;
        content: " ";
        height: 20px;
        width: 20px;
        background: transparent url("/static/svg/no.svg");
        background-size: 20px 20px;
      }
    }
  }

  .cart {
    cursor: pointer;
    display: inline-block;
    width: 21px;
    height: 21px;
    background: url(/static/svg/cart.svg) no-repeat;
    background-size: 21px 21px;

    &:active {
      filter: invert(33%) sepia(25%) saturate(4532%) hue-rotate(338deg) brightness(106%) contrast(102%);
    }
  }

  @media (max-width: 768px) {
    .search-form {
      width: 300px;
    }
  }
`

export const SearchField = () => {
    const dispatch = useDispatch();
    const [article, setArticle] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [autoParts, setAutoParts] = useState([]);
    const getAutoParts = async () => {
        try {
            const {data: parts} = await api.get('carparts/get-carpart-by-article/' + article);
            return parts;
        } catch (e) {
            console.log(e);
        }
        return [];
    }

    const handleButtonClick = async () => {
        const parts = await getAutoParts();
        setAutoParts(parts);
        setIsExpanded(!isExpanded ? !isExpanded : isExpanded);
        console.log(parts);
    }

    const cartHandler = (part) => {
        console.log("part is: ", part)
        let itemCopy = JSON.parse(JSON.stringify(part));
        console.log("ITEM_COPY: ", itemCopy);
        itemCopy['quantity'] = 1;
        dispatch(addToCart(itemCopy));
    }

    return (
        <StyledSearchField>
            <div className="search-form">
                <div className="input-group">
                    <input type="text" className="form-control" id="article-search-input"
                           placeholder="Код запчасти"
                           maxLength="20"
                           onChange={(el) => {
                               setArticle(el.target.value.trim())
                           }}
                    />&nbsp;
                    <div className="input-group-append">
                        <button
                            id="article-search-btn"
                            type="button"
                            onClick={handleButtonClick}
                        >
                            Найти
                        </button>
                    </div>
                </div>
            </div>
            <div id="search-results" className={`search-results ${isExpanded ? '' : 'hidden'}`}>
                <div id="close-button" className="search-close"
                     onClick={() => isExpanded ? setIsExpanded(!isExpanded) : null}/>
                <div className="table-wrapper">

                </div>
                <table className="table table-hover">
                    <tbody id="resultContent">
                    <tr>
                        <th>Артикул</th>
                        <th>Производитель</th>
                        <th>Марка</th>
                        <th>Наименование</th>
                        <th>Статус</th>
                        <th>Время доставки</th>
                        <th>Цена</th>
                        <th>Корзина</th>
                    </tr>
                    {
                        autoParts.map(part => {
                        // autoPartsMock.map(part => {
                            return (
                        <tr key={part.id} className="tr-odd">
                            <td className="center">
                                {part.article}
                            </td>
                            <td key={part.manufacturer} className="center">
                                {part.manufacturer}
                            </td>
                            <td key={part.brand_auto} className="center">
                                {part.brand_auto}
                            </td>
                            <td key={part.name} className="center">
                                {part.name}
                            </td>
                            <td key={part.quantity} className={`left ${part.quantity > 0 ? "yes-field" : "no-field"}`}>
                                {part.quantity > 0 ? "В наличии" : "Под заказ"}
                            </td>
                            <td key={part.delivery_time} className="left">
                                {part.delivery_time + " д."}
                            </td>
                            <td key={part.id} className="center">
                                {Math.round(part.total_price) + " руб."}
                            </td>
                            <td key={part.brand_auto + part.id} className="center">
                                <span className="cart" onClick={() => cartHandler(part)}/>
                            </td>
                        </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </StyledSearchField>
    )
}