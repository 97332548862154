import React, {useEffect, useState} from "react";

import styled from "styled-components";
import NavItem from "../navigator/nav-item";
import {useDispatch} from "react-redux";
import {setIsLoginModalExpanded, setIsMenuModalExpanded} from "../../actions/common.actions";
import {useAuthContext} from "../../providers/auth.provider";

const StyledModalMenu = styled.div`
  z-index: 9;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  outline: 0;

  .modal-full {
    min-width: 100%;
    margin: 0;
  }

  .modal-dialog {
    position: relative;
    width: auto;
    //margin: 0.5rem;
    pointer-events: none;
  }

  .modal-content {
    background-image: linear-gradient(90deg, #FE0A6C, #FF7E06);
    //top: -30px;
    min-height: 100vh;
    width: 100vw;
  }

  .modal-body {
    background: url(/static/png/menu-background.png) 40% 30px no-repeat;
    cursor: auto;
    //background-position: 40% 30px;
  }

  .close {
    background: #fff;
    opacity: 1;
    padding: 18px 21px;
    border-radius: 35px;
    margin-right: 5%;
    margin-top: 3%;
    border-color: transparent;
    text-align: center;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;

    &:hover {
      background: #e7e7e7;
    }

    span {
      width: 19px;
      height: 18px;
      display: inline-block;
      background: url(/static/svg/close-menu.svg) no-repeat;
      position: relative;
      top: 1px;
      z-index: 999;
    }
  }

  .menu-items {
    margin-top: 20vh;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    padding-left: 50px;

    div {
      margin-bottom: 45px;

      span {
        transform: rotate(-90deg);
        display: inline-block;
      }

      & > div {
        position: absolute !important;
        left: 90px;
        margin-left: 0;
        text-decoration: none;
        min-width: 165px;
        color: #ffffff;
        display: inline-block;
        cursor: pointer;

        &:hover:before {
          content: '';
          width: 34px;
          height: 1px;
          background: #fff;
          display: inline-block;
          position: absolute;
          left: -50px;
          top: 10px;
        }
        a {
          text-decoration: none;
          color: white;
        }
      }
    }

    & > div {
      position: relative;
    }

    a {
      &:after {
        transform: rotate(180deg);
        bottom: 5px;
      }
    }
  }


  @media (max-width: 768px) {
    .p-4 {
      margin-top: 150px;
    }

    .user-mob-links {
      padding-left: 30px;
      padding-bottom: 0;

      & > div {
        padding-bottom: 20px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        display: block;
        line-height: 32px;
      }
    }

    .modal-dialog {
      //max-width: 400px !important;
      //margin: 1.75rem auto;
    }

    .close {
      background: transparent;
      margin-right: 0;
      margin-top: 0;
      filter: brightness(0) invert(1);

      &:hover {
        background: transparent;
      }
    }

    .menu-items {
      padding-top: 5vh;
      margin-top: 0;
      margin-left: 30px;
      border-top: 1px solid #fff;
      padding-left: 0;
      width: 100%;
      font-weight: 400;
      line-height: 30px;
      font-size: 16px;
      left: 55px;

      div {
        margin-bottom: 20px;
      }
    }
  }
  //@media (min-width: 576px) {
  //  .modal-dialog {
  //    max-width: 500px;
  //    //margin: 1.75rem auto;
  //  }
  //}
`


export const ModalMenu = () => {
    const auth = useAuthContext();
    const dispatch = useDispatch();
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <StyledModalMenu>
            <div className="modal-full modal-dialog">
                <div className="modal-content">
                    <div className="modal-body p-4">
                        <button className="close" onClick={() => {
                            dispatch(setIsMenuModalExpanded(false));
                        }}>
                            <span/>
                        </button>
                        <div className="row">
                            <div className="offset-sm-7 offset-0 col-12 col-sm-5">
                                {
                                    windowSize[0] < 768 && (
                                        <div className="user-mob-links d-block d-md-none">
                                            <div
                                                onClick={() => {
                                                    dispatch(setIsLoginModalExpanded(true));
                                                }}
                                            >
                                                Логин
                                            </div>
                                            <div>
                                                <NavItem to="register" title='register' onClick={() => {
                                                    dispatch(setIsMenuModalExpanded(false));
                                                }}>Регистрация</NavItem>
                                            </div>
                                            <div>
                                                <NavItem to="cart" title='register' onClick={() => {
                                                    dispatch(setIsMenuModalExpanded(false));
                                                }}>Корзина</NavItem>
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="menu-items">
                                    <div>
                                        <span>01</span>
                                        <NavItem title="basename" to="/" onClick={() => {
                                            dispatch(setIsMenuModalExpanded(false));
                                        }}>
                                            Главная
                                        </NavItem>
                                    </div>
                                    <div>
                                        <span>02</span>
                                        <NavItem title="basename" to="delivery" onClick={() => {
                                            dispatch(setIsMenuModalExpanded(false));
                                        }}>
                                            Доставка
                                        </NavItem>
                                    </div>
                                    <div>
                                        <span>03</span>
                                        <NavItem title="basename" to="about" onClick={() => {
                                            dispatch(setIsMenuModalExpanded(false));
                                        }}>
                                            О нас
                                        </NavItem>
                                    </div>
                                    <div>
                                        <span>04</span>
                                        <div title="basename" to="/" onClick={() => {
                                            dispatch(setIsMenuModalExpanded(false));
                                        }}>
                                            <a href="/static/pdf/policy.pdf">
                                                Пользовательское соглашение
                                            </a>
                                        </div>
                                    </div>
                                    {auth.user &&
                                        (<div>
                                            <span>05</span>
                                            <NavItem title="basename" to="account/orders" onClick={() => {
                                                dispatch(setIsMenuModalExpanded(false));
                                            }}>
                                                Личный кабинет
                                            </NavItem>
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledModalMenu>
    )
}