import React, {useEffect, useState} from "react";

import styled from "styled-components";
import Order from "./order";
import api from "../../utils/api";

const StyledOrderContainer = styled.div`
  //&:before {
  //  content: "";
  //  display: flex;
  //  position: absolute;
  //  height: auto;
  //  //margin-top: 50px;
  //  border-left: 2px solid #efefef;
  //}
  .order-divider {
    width: 100%;
    padding: 10px 0 20px 0;
    margin-top: 30px;
    border-top: 1px solid ${props => props.theme.colors.primaryGray};
  }

  .order-text {
    padding: 5px;
    font-weight: 600;
  }
`

export const OrderContainer = () => {
    const [currentOrders, setCurrentOrders] = useState([]);
    const [archivalOrders, setArchivalOrders] = useState([]);
    useEffect(() => {
        const getUserOrders = async () => {
            await api.get('order/get-user-orders/', {}).then(res => {
                console.log('response is :', res.data);
                setCurrentOrders(res.data.active_orders);
                setArchivalOrders(res.data.archival_orders);
            })
        }
        getUserOrders().catch(console.error);
    }, []);
    return (
        <StyledOrderContainer>
            <div className="order-text">Текущие заказы</div>
            {currentOrders.map(el => {
                return (
                    <Order
                        key={el.order_id}
                        order_id={el.order_id}
                        date_created={Date(el.date_created).toString()}
                        order_total_price={el.order_total_price}
                        orderItems={el.order_items}
                    />
                )
            })}
            <div className="order-divider">
                <div className="order-text">История заказов</div>
            </div>
            {archivalOrders.map(el => {
                return (
                    <Order
                        key={el.order_id}
                        order_id={el.order_id}
                        date_created={Date(el.date_created).toString()}
                        order_total_price={el.order_total_price}
                        orderItems={el.order_items}
                    />
                )
            })}
        </StyledOrderContainer>
    )
}