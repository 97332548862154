import {applyMiddleware, combineReducers, createStore} from 'redux';
import commonReducer from '../reducers/common.reducer';
import cartReducer from '../reducers/cart.reducer';
import localstorageMiddleware from '../middlewares/localstorage.middleware';

const rootReducer = combineReducers({
  common: commonReducer,
  cart: cartReducer,
});

const middlewareEnhancer = applyMiddleware(localstorageMiddleware);

const configureStore = () => {
  return createStore(rootReducer, middlewareEnhancer);
};

export default configureStore;