import React from "react";

import styled from "styled-components";
import {CommonTemplate} from "../components/common-template";

const StyledAboutPage = styled.div`
  
  h1 {
    background: transparent;
  }

  p {
    background: transparent;
  }

  .img {
    &-1 {
      border-style: solid;
      border-width: 0px;
      float: left;
      height: 194px;
      margin: 10px;
      width: 333px;
    }

    &-2 {
      float: right;
      height: 333px;
      margin: 10px;
      width: 186px;
    }
  }

  &-3 {
    float: left;
    height: 159px;
    margin: 20px 10px;
    width: 335px;
  }


  .text-container {
    position: relative;
    padding: 60px;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
  }
`

export const AboutPage = () => {
    return (
        <StyledAboutPage>
            <CommonTemplate>
                <div className="text-container">
                    <h1>О нас</h1>
                    <p>
                        <img alt="" className="img-1"
                             src="https://www.original-carparts.com/ckfinder/userfiles/images/20130731_211817-1.jpg"/>If
                        your business is original car parts and if you want a truly reliable partner with an
                        impeccable
                        reputation, you should familiarize yourself with us.</p>

                    <p>Our Company RONAX since 2004 specializes in export import spare parts from Germany and to
                        Germany
                        from virtually anywhere in the world. The company has its own warehouse in Germany. We have
                        a
                        wide range of stock available oils, tyres and spare parts as OEM and compatibles.</p>

                    <p>In connection with the widespread use of modern information technologies, and a moderate
                        number
                        of professional staff, we can offer you the lowest prices for their professional
                        services.<img
                            alt="" className="img-2"
                            src="https://www.original-carparts.com/ckfinder/userfiles/images/20130731_211729.jpg"/>
                    </p>

                    <p>Due to the large turnover, we have the opportunity to keep the lowest prices on the
                        market.</p>

                    <p><br/>
                        In addition, the company RONAX provides a comprehensive series of integrated services to
                        clients. Your advantage is obvious - rather than treat two or three different companies, you
                        get
                        us all that you need!</p>

                    <p>RONAX provides high quality and timely delivery of original spare parts (Original car
                        parts)at
                        the lowest possible prices. The whole process of your order until you receive it within
                        Germany
                        will take from 1 to 3 days depending on the manufacturer, and from 4 to 10 days for delivery
                        in
                        Russia and Ukraine.</p>

                    <p><img alt="" className="img-3"
                            src="https://www.original-carparts.com/ckfinder/userfiles/images/teileoriginal.jpg"/>
                    </p>

                    <p>Ate as you are willing to see ourselves on the quality of execution of orders, you
                        impenetrably.</p>

                    <p>&nbsp;</p>

                    <p>With us you do not have to wait weeks for an answer to a question about the status of your
                        order.
                        We regularly inform our clients about the status of their orders via email. And also you
                        have
                        the opportunity to monitor the status of your order on line.</p>

                    <p>Rest assured - if you work with RONAX, work is being done without any surprises and
                        curiosities.<br/>
                        In a place with us, you can work on another higher level, and only together we can achieve
                        competitors far behind!</p>
                </div>
            </CommonTemplate>
        </StyledAboutPage>
    )
}