import React from "react";
import {createGlobalStyle, ThemeProvider} from "styled-components";

const theme = {
    colors: {
        primaryGray: "#f0f4f7",
        darkGray: "#A4B5C1FF",
        primaryOrange: "#FF4234",
        lightOrange: "#FE624D",
    },
};

const GlobalStyle = createGlobalStyle`
  *, ::before, ::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto, serif;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  body {
    margin: 0;
    overflow-x: hidden;
    box-sizing: border-box;
    background-color: ${props => props.theme.colors.primaryGray};
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .hidden {
    display: none !important;
  }
`;

export const AppThemeProvider = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle/>
            {props.children}
        </ThemeProvider>
    );
};