import React from "react";

import styled from "styled-components";
import PropTypes from "prop-types";

const StyledOrder = styled.div`
  padding: 5px;

  .order-header {

    button {
      padding: 20px;
      border-radius: 5px;
      width: 100%;

      color: #000000;
      border-color: ${props => props.theme.colors.primaryGray};
      background: ${props => props.theme.colors.primaryGray};

      //color: white;
      //border-color: #d0d0d0;
      ////background: #6c1c18;
      //background-image: linear-gradient(153deg, #FE624D, #FF4234);

      &:active {
        color: white;
        background: ${props => props.theme.colors.primaryOrange};
        border: 1px solid ${props => props.theme.colors.primaryOrange};
      }

      .info-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .info-left {

        font-weight: 500;
        font-size: 16px;
        left: 0;
      }

      .info-right {
        color: #969696;
        //color: #dcdcdc;
        font-size: 12px;
        right: 0;

        &:active {
          color: white;
        }
      }
    }
  }

  .collapse {
    overflow-x: scroll;
    position: relative;
    width: 100%;
  }

  table {
    border-collapse: collapse;
    font-size: 12px;
    width: 100%;
  }

  .table {

    .tr {
      &-odd {
        background-color: transparent;

        th {
          background: transparent;
          font-weight: 500;
          text-transform: none;
        }

        .price {
          font-weight: 600;
        }
      }

      &-even {
        background: rgba(236, 240, 244, 1);
      }
    }

    tbody {
      background: #fafafa;

      tr {
        th {
          background: #e7e7e7;
          //background-color: transparent;
          color: #6e6e6e;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
          border: none;
          padding-bottom: 7px;
        }
      }
    }
  }

`

const Order = ({order_id, date_created, order_total_price, orderItems}) => {
    return (
        <StyledOrder>
            <div className="order-header">
                <button className="btn btn-primary active" type="button" data-bs-toggle="collapse"
                        data-bs-target={`#orderDetail${order_id}`} aria-expanded="true"
                        aria-controls={`orderDetail${order_id}`}>
                    <div className="info-wrapper">
                        <div className="info-left">
                            Заказ №{order_id}, 5 товаров на
                            сумму <b>{order_total_price} руб.</b>
                        </div>
                        <div className="info-right">Заказ оплачен {date_created}</div>
                    </div>
                </button>
            </div>
            <div className="collapse" id={`orderDetail${order_id}`}>
                <table className="table">
                    <tbody>
                    <tr>
                        <th key="Артикул">Артикул</th>
                        <th key="Производитель">Производитель</th>
                        <th key="Марка">Марка</th>
                        <th key="Количество">Количество</th>
                        <th key="Название">Название</th>
                        <th key="Цена">Цена</th>
                    </tr>
                    {
                        orderItems.map(item => {
                            return (
                                <tr className="tr-odd" key={Math.random()}>
                                    <th key={item.article}>{item.article}</th>
                                    <th key={item.manufacturer}>{item.manufacturer}</th>
                                    <th key={item.brand_auto}>{item.brand_auto}</th>
                                    <th key={item.quantity}>{item.quantity}</th>
                                    <th key={item.name}>{item.name}</th>
                                    <th key={item.price} className="price">{item.price} руб.</th>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </StyledOrder>
    )
}

Order.propTypes = {
    order_id: PropTypes.number,
    date_created: PropTypes.string,
    order_total_price: PropTypes.number,
    orderItems: PropTypes.arrayOf(PropTypes.shape({
        article: PropTypes.string,
        manufacturer: PropTypes.string,
        name: PropTypes.string,
        originality: PropTypes.bool,
        price: PropTypes.number,
        quantity: PropTypes.number,
        brand_auto: PropTypes.string,
        model_auto: PropTypes.string,
        generation_auto: PropTypes.string,
    })),
}
export default Order;