import React from "react";
import styled from "styled-components";
import {Header} from "./components/header/header";
import {Footer} from "./components/footer";
import {Outlet} from "react-router-dom";

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  //overflow-x: hidden;
  //overflow-y: hidden;
`

function App() {
    return (
        <StyledApp>
            <Header/>
            <Outlet/>
            <Footer/>
        </StyledApp>
    );
}

export default App;
