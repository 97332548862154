import React from "react";

import styled from "styled-components";

const StyledFooter = styled.div`
  flex-shrink: 0;
  padding: 20px 0;
  color: #a4b5c1;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  //margin-top: 200px;
  //font-size: 12px;
  background: transparent;

  &:focus {
    outline: 0;
  }

  a {
    color: #333333;
    text-decoration: none;
  }

  .container {
    background: transparent;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }

    .justify-content-between {
      justify-content: space-between !important;
      background: transparent;

      .text-left {
        text-align: left !important;
        background: transparent;
        
        .copyright {
          background: transparent;
        }

      }

      .text-right {
        text-align: right !important;
        background: transparent;
      }
    }
  }

  .slider-paging {
    text-align: center;
    position: absolute;
    background: transparent;
    left: 0;
    right: 0;
    //margin: auto;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    top: -3vh;

    span {
      color: #fe4e30;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      background: transparent;
    }

    .slide-left {
      display: inline-block;
      height: 10px;
      width: 19px;
      background: url(static/svg/arrow-left.svg) no-repeat;
      margin: auto 5px auto auto;
      position: relative;
      bottom: 2px;

      &:hover {
        cursor: pointer;
      }
    }

    .slide-right {
      display: inline-block;
      height: 10px;
      width: 19px;
      background: url(static/svg/arrow-right.svg) no-repeat;
      margin: auto auto auto 5px;
      position: relative;
      bottom: 2px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media (max-width: 1500px) {
  }
  
  @media (max-width: 765px) {
    z-index: 1;
    //margin-top: 150px;
  }
`


export const Footer = () => {
    return (
        <StyledFooter>
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-7 col-md-3 text-left">
                        <p className="copyright">Copyright © 2024, Alex Parts</p>
                    </div>
                    <div className="col-5 col-md-3 text-center">
                        <p className="copyright">ИП Костерев Алексей Сергеевич</p>
                        <p className="copyright">ИНН 352839850404</p>
                    </div>
                    <div className="col-5 col-md-3 text-right">
                        Все права защищены
                    </div>
                </div>
            </div>
        </StyledFooter>
    )
}