export const addToCart = (item) => ({
  type: 'CART_ADD_ITEM',
  payload: item,
});

export const subtractItemFromCart = (index) => ({
  type: 'CART_SUBTRACT_ITEM',
  payload: index,
});

export const removeItemFromCart = (index) => ({
  type: 'CART_REMOVE_ITEM',
  payload: index,
});

export const removeAllFromCart = () => ({
  type: 'CART_REMOVE_ALL',
});
