import React, {useEffect, useState} from "react";

import styled from "styled-components";
import {CommonTemplate} from "../components/common-template";
import api from "../utils/api";
import {setToken} from "../utils/session";
import {AuthProvider, useAuthContext} from "../providers/auth.provider";
import {useNavigate} from "react-router-dom";

const StyledRegisterPage = styled.div`

  .input-header-text {
    text-align: left;
    font-weight: 600;
    font-size: 30px;
    background: transparent;
    padding: 50px 0 50px 0;
  }
  
  .register-error {
    color: red;
    text-align: left;
    font-size: 16px;
    padding-bottom: 20px;
  }

  .input-block {
    width: 600px;
    padding: 20px 50px;
    background: transparent;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #f0f4f7;
  }
  .required {
    &:after {
      color: red;
      font-size: 10px;
      content: " * обязательное поле";
    }
  }
  input {
    margin-bottom: 5px;
    background: #f0f4f7;
    border-radius: 10px;
    border: none;
    padding: 0 20px;
    height: 40px;
    width: 300px;
    color: black;
    font-size: 12px;
    font-weight: 400;

    &:hover {
      //background: transparent;
      border: none !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
      opacity: 1 !important;
    }

    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
      opacity: 1 !important;
      outline: none;
    }

    &:active {
      border: none;
    }
  }

  .big-buttons {
    text-align: left;
    padding: 50px 0 50px 0;
    margin-bottom: 25px;
    background: transparent;

    span {
      background: transparent;
    }

    .submit-button {
      background-image: linear-gradient(153deg, ${props => props.theme.colors.lightOrange}, ${props => props.theme.colors.primaryOrange});
      border: 1px solid ${props => props.theme.colors.lightOrange};
      border-radius: 40px;
      text-align: center;
      color: #fff;
      font-size: 14px !important;
      font-weight: 500;
      padding: 15px 72px;

      &:active {
        background-image: linear-gradient(153deg, #d04e3f, #b72f25);
      }
    }
  }

  .col-sequence {
    //margin-top: 10px;
    height: 100px;
    display: flex;
    flex-direction: column;

    span {
      padding-bottom: 5px;
      text-align: left;
    }

    input {
      width: 100%;
    }
  }


  .col-section {
    & > span {
      padding-top: 20px;
      margin-bottom: 20px;
      font-weight: 600;
      display: block;
      width: 100%;
      text-align: left;
      border-bottom: 1px solid #f0f4f7;
    }
  }

  @media (min-width: 1200px) {
    .container {
      padding: 10px 150px 10px 150px;
    }
  }
  @media (max-width: 768px) {
    .big-buttons {
      padding: 0 50px 50px;
      margin-bottom: 25px;
      margin-top: 45px;

      .submit-button {
        border: 1px solid ${props => props.theme.colors.lightOrange};
        border-radius: 40px;
        padding: 10px 42px;
      }
    }

  }
`

export const RegisterPage = () => {
    const auth = useAuthContext();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        index: '',
        mobilePhone: '',
    });
    const [regError, setRegError] = useState("");

    useEffect(() => {
        if (auth.user) {
            navigate("/");
        }
    })

    const handleChange = (e) => {
        setRegError(regError ? {} : regError);
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Отправка данных:', formData);
        api.post('auth/users/', formData).then((res) => {
            // eslint-disable-next-line no-unused-expressions
            res.data.token.access ? setToken(res.data.token.access) : null;
            auth.reloadUser();
            navigate("/");
        }).catch((e) => {
            const responseData = e.response.data;
            setRegError(responseData ? responseData : {'Сервер': 'Внутренние ошибки'});
        });
    };

    return (
        <StyledRegisterPage>
            <CommonTemplate>
                <div className="container text-center">
                    <div className="input-header-text">Регистрация</div>
                    {
                        Boolean(regError) && (
                            Object.entries(regError).map((err) => {
                                return (
                                    <div className="register-error">
                                        {err[0]} - {err[1]}
                                    </div>
                                )
                            })
                        )
                    }

                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col col-sequence">
                                <span className="required">Почта</span>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Почта"
                                    value={formData.email} onChange={handleChange}
                                />
                            </div>
                            <div className="col col-sequence">
                                <span className="required">Пароль</span>
                                <input
                                    type="password"
                                    id="password"
                                    placeholder="Пароль"
                                    name="password"
                                    maxLength="20"
                                    value={formData.password} onChange={handleChange}
                                />
                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*    <div className="col col-sequence">*/}
                        {/*        <span>Почта</span>*/}
                        {/*        <input*/}
                        {/*            type="email"*/}
                        {/*            id="email"*/}
                        {/*            name="Почта"*/}
                        {/*            placeholder="Почта"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-section">
                            <span></span>
                            <div className="row">
                                <div className="col col-sequence">
                                    <span>Имя</span>
                                    <input
                                        type="text"
                                        id="name"
                                        name="firstName"
                                        placeholder="Имя"
                                        value={formData.firstName} onChange={handleChange}
                                    />
                                </div>
                                <div className="col col-sequence">
                                    <span>Фамилия</span>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Фамилия"
                                        value={formData.lastName} onChange={handleChange}
                                    />
                                </div>
                                {/*<div className="col col-sequence">*/}
                                {/*    <span>Отчество</span>*/}
                                {/*    <input*/}
                                {/*        type="text"*/}
                                {/*        id="second_name"*/}
                                {/*        name="Отчество"*/}
                                {/*        placeholder="Отчество"*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="col-section">
                            <span></span>
                            <div className="row">
                                <div className="col col-sequence">
                                    <span>Улица</span>
                                    <input
                                        type="text"
                                        id="street"
                                        name="street"
                                        placeholder="Улица"
                                        value={formData.street} onChange={handleChange}
                                    />
                                </div>
                                <div className="col col-sequence">
                                    <span>Город</span>
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        placeholder="Город"
                                        value={formData.city} onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-sequence">
                                    <span>Индекс</span>
                                    <input
                                        type="number"
                                        id="zipcode"
                                        name="index"
                                        placeholder="Индекс"
                                        value={formData.index} onChange={handleChange}
                                    />
                                </div>
                                <div className="col col-sequence">
                                    <span>Телефон</span>
                                    <input
                                        type="phone"
                                        id="mobilePhone"
                                        name="mobilePhone"
                                        placeholder="Телефон"
                                        value={formData.mobilePhone} onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="big-buttons">
                            <button
                                type="submit"
                                className="submit-button"
                                tabIndex="reg-send-btn"
                            >
                                <span className="ui-button-text">Отправить</span>
                            </button>
                        </div>
                    </form>
                </div>
            </CommonTemplate>
        </StyledRegisterPage>)
}