import React, {useEffect, useState} from "react";

import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {HeaderDesktop} from "./header-desktop";
import {HeaderMobile} from "./header-mobile";

const StyledHeader = styled.div`
  .head-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .my-auto {
    //margin-top: auto !important;
  }

  .container-header {
    position: relative;
    left: 0;
    //background: transparent;
    width: 100vw;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .head-phone {
    font-size: 14px;
    cursor: pointer;
    line-height: 25px;

    a {
      color: #333333;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        border-bottom: 1px solid #333333;
      }
    }

    &::before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 20px;
      margin-right: 11px;
      background: url(/static/svg/m-icon.svg) no-repeat;
      position: relative;
      top: 5px;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 11px;
      height: 5px;
      background: url(/static/svg/arrow-icon.svg) no-repeat;
      position: relative;
      bottom: 0px;
      margin-left: 10px;
    }
  }
`


export const Header = () => {
    const currentLocation = useLocation();

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [])
    return (
        <StyledHeader>
            <div className={`container-header ${currentLocation.pathname !== '/' ? "bg-transparent" : ""}`}>
                {windowSize[0] > 767 ? (<HeaderDesktop/>) : (<HeaderMobile/>)}
            </div>
        </StyledHeader>
    )
}