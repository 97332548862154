import React from "react";

import styled from "styled-components";
import NavItem from "./navigator/nav-item";
import {useLocation} from "react-router-dom";

const StyledCommonPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .location-info {
    font-size: 16px;
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 0;

    h2 {
      display: flex;
      font-size: 16px;
      margin-right: 5px;
    }

    .basename {
      color: #FF4234;
      cursor: pointer;
    }
  }

  .common-wrapper {
    width: 1200px;
  }

  .common-container {
    border-radius: 10px;
    background: white;

  }


  @media (max-width: 1200px) {
    .common-wrapper {
      width: 1000px;
    }
  }

  @media (max-width: 1000px) {
    .common-wrapper {
      width: 700px;
    }
  }

  @media (max-width: 768px) {
    .common-wrapper {
      width: 400px;
    }
  }
`

export const CommonTemplate = ({children}) => {
    const currentLocation = useLocation();
    const locations = {
        "/about": "/ О нас",
        "/cart": "/ Корзина",
        "/register": "/ Регистрация",
        "/account": "/ Личный кабинет",
        "/account/orders": "/ Личный кабинет / Заказы",
        "/account/personal": "/ Личный кабинет / О себе",
    };
    return (
        <StyledCommonPage>
            <div className="common-wrapper">
                <div className="location-info">
                    <h2>
                        <NavItem title="basename" to="/">
                            <h2 className="basename">Главная</h2>
                        </NavItem>
                        {locations[currentLocation.pathname]}
                    </h2>
                </div>
                <div className="common-container">
                    {children}
                </div>
            </div>
        </StyledCommonPage>
    )
}