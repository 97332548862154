import {
    initializeItemsQuantity,
    getItemsQuantity,
} from '../helpers/cart.reducer.helper';

const initialState = {
    cartItems: JSON.parse(localStorage.getItem('cart')) || [],
    itemsQuantity: initializeItemsQuantity(),
};

const cartReducer = (state = initialState, action) => {
    const removeItemByid = (cartItems, id) => {
        const indexToRemove = cartItems.findIndex((item) => item.id === id);

        if (indexToRemove !== -1) {
            return [
                ...cartItems.slice(0, indexToRemove),
                ...cartItems.slice(indexToRemove + 1),
            ];
        }

        return cartItems;
    };

    switch (action.type) {
        case 'CART_ADD_ITEM':
            const product = action.payload;
            const existingProductIndex = state.cartItems.findIndex(
                (item) => item.id === product.id
            );

            if (existingProductIndex !== -1) {
                const updatedCartAfterAdd = state.cartItems.map((item) =>
                    item.id === product.id
                        ? {...item, quantity: item.quantity + Number(product.quantity)}
                        : item
                );

                return {
                    ...state,
                    cartItems: updatedCartAfterAdd,
                    itemsQuantity: getItemsQuantity({cartItems: updatedCartAfterAdd}),
                };
            } else {
                const updatedCartAfterAdd = [...state.cartItems, product]
                return {
                    ...state,
                    cartItems: updatedCartAfterAdd,
                    itemsQuantity: getItemsQuantity({cartItems: updatedCartAfterAdd}),
                };
            }

        case 'CART_SUBTRACT_ITEM':
            const idToSubtract = action.payload;
            const itemToSubtract = state.cartItems.find((item) => item.id === idToSubtract);
            console.log("ITEM TO SUBTRACT", itemToSubtract);
            if (itemToSubtract && itemToSubtract.quantity > 1) {
                const updatedCartAfterSubtract = state.cartItems.map((item) =>
                    item.id === idToSubtract
                        ? {...item, quantity: item.quantity - 1}
                        : item
                );
                console.log("UPDATED CART ITEMS: ", state.cartItems);
                return {
                    ...state,
                    cartItems: updatedCartAfterSubtract,
                    itemsQuantity: getItemsQuantity({cartItems: updatedCartAfterSubtract}),
                };
            } else if (itemToSubtract) {
                const updatedCartAfterRemove = removeItemByid(state.cartItems, idToSubtract);
                console.log("UPDATED CART ITEMS AFTER DELETE: ", state.cartItems);
                return {
                    ...state,
                    cartItems: updatedCartAfterRemove,
                    itemsQuantity: getItemsQuantity({cartItems: updatedCartAfterRemove}),
                };
            }

            return state;

        case 'CART_REMOVE_ITEM':
            const idToRemove = action.payload;
            const indexToRemove = state.cartItems.findIndex(
                (item) => item.id === idToRemove
            );

            if (indexToRemove !== -1) {
                const removedItemQuantity = state.cartItems[indexToRemove].quantity;
                const updatedCartAfterRemove = [
                    ...state.cartItems.slice(0, indexToRemove),
                    ...state.cartItems.slice(indexToRemove + 1),
                ];
                return {
                    ...state,
                    cartItems: updatedCartAfterRemove,
                    itemsQuantity: getItemsQuantity({cartItems: updatedCartAfterRemove}) - removedItemQuantity,
                };
            }
            return {
                ...state
            }

        case 'CART_REMOVE_ALL':
            return {
                cartItems: [],
                itemsQuantity: 0,
            }
        default:
            return state;
    }
};

export default cartReducer;