export const setIsLoginModalExpanded = (value) => ({
    type: 'SET_IS_LOGIN_MODAL_EXPANDED',
    value,
});

export const setIsMenuModalExpanded = (value) => ({
    type: 'SET_IS_MENU_MODAL_EXPANDED',
    value,
});


export const setAccountSection = (value) => ({
    type: 'SET_ACCOUNT_SECTION',
    value,
});
