import React, {useEffect, useState} from "react";
import PropTypes, {string} from "prop-types";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";

import {matchPaths} from "./nav-item.service";

const StyledNavItem = styled.div`
`;

const NavItem = ({children, to, title, onClick, paths}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(matchPaths(location.pathname, to, paths));

    useEffect(() => {
        setIsActive(matchPaths(location.pathname, to, paths));
    }, [location, to, paths]);

    function open() {
        if (onClick) {
            onClick();
            navigate(to);
        } else {
            navigate(to);
        }
    }

    return (
        <StyledNavItem
            // active={isActive}
            // tooltip={title}
            onClick={open}>
            {children}
        </StyledNavItem>
    );
};

NavItem.propTypes = {
    to: PropTypes.string,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

export default NavItem;