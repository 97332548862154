const initialState = {
    isLoginModalExpanded: false,
    isMenuModalExpanded: false,
    accountSection: 'orders',
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_IS_LOGIN_MODAL_EXPANDED':
            return {
                ...state,
                isLoginModalExpanded: action.value,
            };
        case 'SET_IS_MENU_MODAL_EXPANDED':
            return {
                ...state,
                isMenuModalExpanded: action.value,
            };
        case 'SET_ACCOUNT_SECTION':
            return {
                ...state,
                accountSection: action.value,
            };
        default:
            return state;
    }
};

export default commonReducer;