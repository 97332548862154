export const initializeItemsQuantity = () => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    let quantity = 0;
    cartItems.map((item) => quantity += item.quantity);
    return quantity;
}


export const getItemsQuantity = (state) => {
    let quantity = 0;
    state.cartItems.map((item) => quantity += item.quantity);
    return quantity;
}