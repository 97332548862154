import React from "react";

import styled from "styled-components";
import {useAuthContext} from "../../providers/auth.provider";

const StyledInputBlock = styled.div`
  width: 100%;

  .input-header-text {
    font-weight: 600;
    font-size: 30px;
    background: transparent;
    padding: 20px 50px;
  }

  .input-block {
    width: 100%;
    padding: 20px 50px;
    background: transparent;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #f0f4f7;
  }

  input {
    margin-bottom: 5px;
    background: #f0f4f7;
    border-radius: 28px;
    border: none;
    padding: 0 20px;
    height: 40px;
    //width: 300px;
    color: black;
    font-size: 12px;
    font-weight: 400;

    &:hover {
      //background: transparent;
      border: none !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
      opacity: 1 !important;
    }

    &:focus {
      box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
      opacity: 1 !important;
      outline: none;
    }

    &:active {
      border: none;
    }
  }

  @media (max-width: 768px) {
    .input-block {
      padding: 10px;
      width: 100%;
    }

    input {
      width: 180px;
    }
  }
`

export const InputBlock = () => {
    const auth = useAuthContext();
    console.log(auth.user)
    return (
        <StyledInputBlock>
            <div className="input-block">
                <input
                    type="text"
                    id="username"
                    name="login"
                    placeholder="Логин"
                />
                <input
                    type="password"
                    id="password"
                    placeholder="Пароль"
                    name="password"
                    maxLength="20"
                />
                <input
                    type="password"
                    id="password"
                    placeholder="Подтвердите пароль"
                    name="password"
                    maxLength="20"
                />
            </div>
            <div className="input-block">
                <input
                    type="text"
                    id="name"
                    name="Имя"
                    placeholder="Имя"
                />
                <input
                    type="text"
                    id="surname"
                    name="Фамилия"
                    placeholder="Фамилия"
                />
                <input
                    type="text"
                    id="second_name"
                    name="Отчество"
                    placeholder="Отчество"
                />
            </div>
            <div className="input-block">
                <input
                    type="text"
                    id="street"
                    name="Улица"
                    placeholder="Улица"
                />
                <input
                    type="text"
                    id="city"
                    name="Город"
                    placeholder="Город"
                />
                <input
                    type="number"
                    id="zipcode"
                    name="Индекс"
                    placeholder="Индекс"
                />
            </div>
            <div className="input-block">
                <input
                    type="phone"
                    id="phone"
                    name="Телефон"
                    placeholder="Телефон"
                />
                <input
                    type="email"
                    id="email"
                    name="Почта"
                    placeholder="Почта"
                />
            </div>
        </StyledInputBlock>
    )
}