import React, {useEffect, useState} from "react";

import styled from "styled-components";
import PropTypes from "prop-types";
import api from "../../utils/api";

const SpanLogo = styled.span`
  content: "";
  display: inline-block;
  width: 70px;
  height: 70px;
  background: url(/static/${props => props.logopath}) no-repeat;
  background-size: 70px 70px;
  border-radius: 100%;
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
    background-size: 40px 40px;
  }
`

const StyledSelectedCarBody = styled.div`

  max-width: 1000px;
  animation: show-container 0.5s forwards;

  .row {
    &:nth-child(2) {
      margin-top: 40px;
    }

    .col {
      display: flex;
      justify-content: center;
    }
  }

  .part-text {
    font-size: 14px;
    text-align: center;
    width: 70px;
    font-weight: 600;
  }

  .part-wrapper {
    cursor: pointer;
    width: 70px;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid black;
    }
  }

  @keyframes show-container {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1500px) {

    max-width: 800px;

  }

  @media (max-width: 1200px) {

    max-width: 600px;

  }

  @media (max-width: 768px) {

    max-width: 400px;


    .part-wrapper {
      width: 40px;
    }

    .part-text {
      width: 40px;
      font-size: 10px;
    }
  }
`
export const SelectedCarBody = ({selectedModel, setSelectedCarBody}) => {
    const [carBodyList, setCarBodyList] = useState([]);

    useEffect(() => {
        api.get(`carparts/get-generation-model/${selectedModel}/`).then(res => {
            setCarBodyList(res.data);
        });

    }, [])

    const generateRowsList = (arr) => {
        //rounds up the result of dividing the length of the list of brands by 5 and creates an array
        let rowsList = [];
        for (let i = 1; i < Math.ceil(arr.length / 5) + 1; i++) {
            rowsList.push(i);
        }
        return rowsList;
    }
    return (
        <StyledSelectedCarBody className="container">
            {
                //creates a sequence of div tags with five max elements inside
                generateRowsList(carBodyList).map((row) => {
                    return (
                        <div key={row} className={`row `}>
                            {
                                carBodyList.slice((row - 1) * 5, row * 5).map(part => {
                                    return (
                                        <div key={part.name} className="col">
                                            <div className="part-wrapper">
                                                <div className="part-text" onClick={() => {
                                                    setSelectedCarBody(part.name);
                                                }}>{part.name}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </StyledSelectedCarBody>
    )
}

SelectedCarBody.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string,
        items: PropTypes.object,
    })),
    selectBrandFunction: PropTypes.func,
}