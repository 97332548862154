import NavItem from "../navigator/nav-item";
import {setIsLoginModalExpanded, setIsMenuModalExpanded} from "../../actions/common.actions";
import {LoginPopup} from "../login-modal/login-popup";
import {ModalMenu} from "../menu-modal/menu-modal";

import React from "react";

import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {useAuthContext} from "../../providers/auth.provider";

const StyledHeader = styled.div`
  position: relative;
  height: 150px;
  margin-top: 20px;
  z-index: 700;
  line-height: 0.5;
  //background-color: transparent;

  & div, a {
    background-color: transparent;
  }

  .header-text {
    font-weight: 600;
    font-size: 32px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    //background: transparent;
  }


  .desktop-row {

  }

  .mobile-row {
    display: none;
  }

  img {
    position: relative;
    z-index: 2;
    border: none;
    vertical-align: middle;
    height: 150px;
    //background: transparent;
  }

  .head-logo {
    cursor: pointer;
  }

  .head-phone {
    text-align: right;
  }

  .login-links {
    text-align: right;

    & > span, a {
      background: transparent;
      font-weight: 600;
      line-height: 20px;
      display: inline-block;
      margin-right: 30px;
      color: #333333;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        border-bottom: 1px solid #333333;
      }
    }

    .text-capitalize {
      text-transform: capitalize !important;
    }
  }

  .profile-link {
    text-align: center;
    background: transparent;

    .bold {
      font-weight: 600;
    }

    span {
      color: black;
      background: transparent;
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.colors.primaryOrange};
      }
    }

    .dropdown {
      background: transparent;

      ul {
        background: #f0f4f7;
      }

      li {
        background: transparent;
      }
    }
  }

  .menu-btn {
    margin-top: 15px;
    width: 60px;
    height: 60px;
    background: url(/static/svg/menu.svg) no-repeat;
    display: inline-block;
  }

  .cart-wrapper {
    cursor: pointer;
    width: 50px;
    height: 40px;
    border-radius: 15px;
    background-image: linear-gradient(153deg, ${props => props.theme.colors.primaryOrange}, ${props => props.theme.colors.lightOrange});
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    &:hover {
      background-image: linear-gradient(153deg, #fc2d1e, #fd7b47);
    }
  }

  .cart-logo {
    //padding: 1px 20px 1px 2px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(/static/svg/cart.svg) no-repeat;
    background-size: 20px 20px;
    filter: brightness(0) invert(1);
  }

  .cart-quantity {
    font-weight: 600;
    font-size: 18px;
    display: inline-flex;
    justify-content: center;
    filter: brightness(0) invert(1);
  }

  @media (max-width: 1200px) {
    img {
      height: 100px;
    }
  }

  @media (max-width: 768px) {
    img {
      height: 60px;
    }

    .head-phone {
      margin-top: 25px;
      display: flex;
      justify-content: center;
    }
  }

`

export const HeaderDesktop = () => {
    const dispatch = useDispatch();
    const auth = useAuthContext();
    const isLoginModalExpanded = useSelector((state) => state.common.isLoginModalExpanded);
    const isMenuModalExpanded = useSelector((state) => state.common.isMenuModalExpanded);
    const itemsQuantity = useSelector((state) => state.cart.itemsQuantity);
    return (
        <StyledHeader>
            <div className="row h-100 desktop-row">
                <div className="col-md-2 col-5 col-xl-4 my-auto head-wrapper">
                    <div className="head-logo">
                        <NavItem
                            to=""
                            title="basename"
                        >
                            <img src="/static/png/LOGO.png" alt='logo'/>
                        </NavItem>
                    </div>
                    <div className="header-text">
                        Alex Parts
                    </div>
                </div>

                <div className="col-2 col-md-4 col-xl-2 my-auto d-none d-md-block">
                    <div className="head-phone" data-original-title="" title="">
                        <a href="tel:+7 910 476 51 20">
                            +7 910 476 51 20
                        </a>
                    </div>
                </div>
                <div
                    className="col-1 col-md-4 col-xl-2 my-auto d-none d-sm-flex flex-column align-items-end justify-content-between">
                    <NavItem to='cart' title='cart'>
                        <div className="cart-wrapper">

                            <span className="cart-logo"/>
                            <div className="cart-quantity">{itemsQuantity}</div>

                        </div>
                    </NavItem>
                </div>
                {
                    auth.user ? (
                        <div className="col-5 col-md-1 col-xl-1 my-auto d-none d-sm-block">
                            <div className="profile-link">
                                <div className="dropdown">
                                        <span className="dropdown-toggle bold" data-bs-toggle="dropdown"
                                              aria-expanded="false">
                                            {auth.user.email}
                                        </span>
                                    <ul className="dropdown-menu dropdown-menu-dark">
                                        <li><span className="dropdown-item"><NavItem title="personal" to='account/personal'>Профиль</NavItem></span></li>
                                        <li>
                                            <hr className="dropdown-divider"/>
                                        </li>
                                        <li><span className="dropdown-item"><NavItem title="orders" to='account/orders'>Мои заказы</NavItem></span></li>
                                        <li>
                                            <hr className="dropdown-divider"/>
                                        </li>
                                        <li onClick={() => {
                                            auth.logout()
                                        }}>
                                            <span className="dropdown-item">Выйти</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="col-5 col-md-1 col-xl-1 my-auto d-none d-sm-block">
                            <div className="login-links">
                                    <span
                                        role="button"
                                        className="text-capitalize"
                                        onClick={() => {
                                            dispatch(setIsLoginModalExpanded(true))
                                        }}
                                    >
                                        логин
                                    </span>
                                {isLoginModalExpanded && (<LoginPopup/>)}
                            </div>
                        </div>
                    )
                }
                <div className="col-1 my-auto text-right offset-4 offset-sm-0">
                    <p role="button" className="menu-btn" onClick={() => {
                        dispatch(setIsMenuModalExpanded(true));
                    }}/>
                    <span className={`${isMenuModalExpanded ? '' : 'hidden'}`}>
                                <ModalMenu/>
                    </span>
                </div>
            </div>
        </StyledHeader>
    )
}