import React from "react";

import styled from "styled-components";
import {InputBlock} from "../input-block/input-block";

const StyledPersonalInfo = styled.div`
  width: 100%;
  left: 0;
  position: relative;

  .info-container {
    display: flex;
  }

  .info-wrapper {
    width: 50%;
  }
`

export const PersonalInfo = () => {
    return (
        <StyledPersonalInfo>
            <div className="info-container">
                <div className="info-wrapper">
                    <InputBlock/>
                </div>
                {/*<div className="info-wrapper">*/}
                {/*    <InputBlock/>*/}
                {/*</div>*/}
            </div>
        </StyledPersonalInfo>
    )
}