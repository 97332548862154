import axios from "axios";
import Cookies from 'js-cookie'
import {API_URL} from "../config";
import {getToken} from "./session";

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
    baseURL: API_URL,
    responseType: 'json',
    responseEncoding: 'utf8',
});

axiosInstance.interceptors.request.use(config => {
    const token = getToken();
    config.headers['X-CSRFTOKEN'] = Cookies.get('csrftoken');
    console.log('interceptor below')
    console.log(Cookies.get('csrftoken'))
    // config.headers["Access-Control-Allow-Credentials"] = true;
    // config.headers["Access-Control-Allow-Headers"] = "x-requested-with";
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }

    return config;
});

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            console.log('Ошибка данных:', error.response.data);
            console.log('Статус ошибки:', error.response.status);
            console.log('Заголовки ошибки:', error.response.headers);
        } else if (error.request) {
            console.log('Ошибка запроса:', error.request);
        } else {
            console.log('Ошибка:', error.message);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;