import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {AppThemeProvider} from "./providers/theme.provider";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {MainPage} from "./pages/main.page";
import {Provider} from "react-redux";
import configureStore from "./stores/common.store";
import {RegisterPage} from "./pages/register.page";
import {AboutPage} from "./pages/about.page";
import {AccountPage} from "./pages/account.page";
import {AuthProvider} from "./providers/auth.provider";
import {OrderContainer} from "./components/order/order-container";
import {PersonalInfo} from "./components/personal-info/personal-info";
import {CartPage} from "./pages/cart.page";
import {DeliveryPage} from "./pages/delivery.page";

const root = ReactDOM.createRoot(document.getElementById('root'));
const commonStore = configureStore()

root.render(
    <AppThemeProvider>
        <AuthProvider>
            <Provider store={commonStore}>
                <BrowserRouter basename="/">
                    <Routes>
                        <Route path="/" element={<App/>}>
                            <Route index element={<MainPage/>}/>
                            <Route exact path="register" element={<RegisterPage/>}/>
                            <Route exact path="delivery" element={<DeliveryPage/>}/>
                            <Route exact path="about" element={<AboutPage/>}/>
                            <Route exact path="cart" element={<CartPage/>}/>
                            <Route path="account" element={<AccountPage/>}>
                                {/*<Route exact path="groups" element={<Outlet/>}>*/}
                                <Route index path="orders" element={<OrderContainer/>}/>
                                <Route path="personal" element={<PersonalInfo/>}/>
                                {/*</Route>*/}
                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Provider>
        </AuthProvider>
    </AppThemeProvider>
)
;